// hàm setcookie
function setCookie(name, value, minutes) {
    var expires;
    if (minutes) {
        var date = new Date();
        date.setTime(date.getTime() + (minutes * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

// hàm lấy cookie
function getCookie(c_name) {
    if (document.cookie.length > 0) {
        c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
            c_start = c_start + c_name.length + 1;
            c_end = document.cookie.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = document.cookie.length;
            }
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
    return "";
}


var url = "http://localhost:8000";
var jlptSocket = "http://localhost:8008";

if(window.location.href.indexOf("jlpt-test") !== -1){
  jlptSocket = "https://count-test.dungmori.com";
  url = "https://web-test.dungmori.com";
}

//nếu là deploy trên web thật
else if(window.location.href.indexOf("dungmori.com") !== -1){
    jlptSocket = "https://mjt-count.dungmori.com";
    url = "https://dungmori.com";
}
else {

}
