window._vi = {
    "back": "Quay lại",
    "download": "Tải xuống",
    "view_ticket": "Xem phiếu điểm",
    "view_certificate": "Xem bằng",
    "page_title": "Dungmori | Thi thử JLPT online",

    "header_title": "Nền tảng học tiếng Nhật online số 1 tại Việt Nam",

    "lesson_title": "Mori Japanese Test",
    "lesson_time": "Thời gian làm đề: {0} phút kể từ khi bắt đầu",
    "lesson_end":"---Hết---",
    "lesson_finished": "Bài thi đã kết thúc",
    "lesson_result": "Vui lòng điền thông tin để xem kết quả",
    "lesson_vocabulary": "Từ vựng",
    "lesson_grammar": "Ngữ pháp",
    "lesson_listening": "Nghe hiểu",
    "lesson_kanji": "Chữ hán",
    "lesson_reading": "Đọc hiểu",
    "lesson_question_done": "Đã làm {0} câu ",
    "lesson_submit": "Nộp bài",

    "join_at": "Bạn vào thi lúc",
    "time_left": "Thời gian còn lại",
    "quantity_people_exam": "Số người đang thi",

    "audio_not_support_brown": "Trình duyệt của bạn không hỗ trợ nghe audio",

    "rankings": "Bảng xếp hạng",
    "save_information": "Xem kết quả",
    "save_confirm": "Bạn có chắc chắn với các thông tin vừa nhập? Thông tin đã nhập sẽ không thể hoàn tác.",
    "saved": "Đã lưu",
    "receive_certificate": "Nhận bằng",
    "share_result": "Chia sẻ",
    "note": "Lưu ý",
    "please_fill_out": "Hoàn tất thông tin cá nhân hiển thị trên bằng và ấn Hoàn thành",
    "see_answer": "Xem kết quả",
    "certificate_reg": "Đăng ký nhận bằng",

    "modal_header": "Thông tin cá nhân để nhận bằng",
    "modal_title": "Bạn vui lòng điền đầy đủ các thông tin trong form bên dưới để Dũng Mori có thể in và chuyển bằng về địa chỉ nhà bạn nhé!",
    "modal_fullname": "Họ và tên",
    "modal_dob": "Ngày sinh",
    "modal_mobile": "Số điện thoại",
    "modal_email": "Email",
    "modal_address": "Địa chỉ",
    "modal_select_country": "Quốc gia",
    "modal_note": "Ghi chú",
    "modal_btn_close": "Huỷ bỏ",
    "modal_btn_send": "Gửi",
    "modal_address_placeholder": "Vui lòng điền chi tiết thông tin địa chỉ theo mẫu: Số nhà - Đường/Xóm - Quận/Huyện - Tỉnh/Thành",
    "modal_postalcode_placeholder": "Mã bưu cục",

    "page_not_found": "Không có dữ liệu",

    "msg_required": "Trường này không được để trống",
    "msg_email_format": "Email không đúng định dạng",
    "msg_mobile_format": "Số điện thoại không đúng định dạng",
    "msg_ise": "Có lỗi xảy ra. Vui lòng thực hiện lại sau!",

    "country_vi": "Việt Nam",
    "country_jp": "Nhật Bản",
    "postcode": "Tra cứu mã bưu cục",

    "fullname_placeholder": "Nhập họ và tên",
    "dob_placeholder": "Chọn ngày sinh",
    "email_placeholder": "Nhập email",
    "mobile_placeholder": "Nhập số điện thoại",
    "address_placeholder": "Chọn địa chỉ",
    "level_placeholder": "Trình độ hiện tại",
    "career_placeholder": "Nghề nghiệp hiện tại",
    "japan_option": "Nhật Bản",
    "other_option": "Khác",

    "good_skill": "Wow!!! Bạn thực sự đang làm rất tốt phần %s. Hãy tiếp tục duy trì phong độ này nhé!"
}
