window._en = {
    "back": "Back",
    "download": "Download",
    "view_ticket": "View card",
    "view_certificate": "View certificate",
    "page_title": "Dungmori Japanese Test",

    "header_title": "The best online Japanese learning platform in Vietnam.",

    "lesson_title": "Mori Japanese Test",
    "lesson_time": "Total Time: {0} minutes",
    "lesson_end":"---The End---",
    "lesson_finished": "The test finished",
    "lesson_result": "Submit information to get result",
    "lesson_vocabulary": "Vocabulary",
    "lesson_grammar": "Grammar",
    "lesson_listening": "Listening",
    "lesson_kanji": "Kanji",
    "lesson_reading": "Reading",
    "lesson_question_done": "{0} questions completed",
    "lesson_submit": "Submit",

    "join_at": "You joined at",
    "time_left": "Time Left",
    "quantity_people_exam": "Online examinees",

    "audio_not_support_brown": "Your browser does not support the audio element.",

    "rankings": "Ranking",
    "share_result": "Share",
    "save_information": "Submit",
    "save_confirm": "Are you sure you want to submit this form and get result?",
    "saved": "Saved",
    "receive_certificate": "Get certificate",
    "note": "Note",
    "please_fill_out": "Please fill out all empty field in certificate and click Submit",
    "see_answer": "View answer",
    "certificate_reg": "Get certificate",

    "modal_header": "Personal information for certificate delivery",
    "modal_title": "Please complete the fields below so Dungmori can deliver your certificate correctly",
    "modal_fullname": "Fullname",
    "modal_dob": "Date of birth",
    "modal_mobile": "Mobile/Phone",
    "modal_email": "Email",
    "modal_address": "Address",
    "modal_select_country": "Country",
    "modal_note": "Note",
    "modal_btn_close": "Cancel",
    "modal_btn_send": "Send",
    "modal_address_placeholder": "Address",
    "modal_postalcode_placeholder": "Postal Code",

    "page_not_found": "Page Not Found",

    "msg_required": "Please enter this fields",
    "msg_email_format": "Incorrect email format",
    "msg_mobile_format": "Incorrect mobile format",
    "msg_ise": "Internal Server Error",

    "country_vi": 'Vietnam',
    "country_jp": 'Japan',
    "postcode": "Postcode for {0}",

    "fullname_placeholder": "Enter full name",
    "dob_placeholder": "Enter date of birth",
    "email_placeholder": "Enter email",
    "mobile_placeholder": "Enter phone number",
    "address_placeholder": "Your location",
    "level_placeholder": "Your level",
    "career_placeholder": "Your career",
    "japan_option": "Japan",
    "other_option": "Other"
}
